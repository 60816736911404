
import { Vue, Component, Watch, Prop } from "vue-property-decorator";
import { UFliterals } from "@/utils/literals";
import { ISlide } from "@/schemas/ISlide";
import SlideElement from "@/components/slides/SlideElement.vue";
import { ISlideElement } from "@/schemas/ISlideElement";

@Component({
  name: "IntroSlides",
  components: {
    SlideElement,
  },
})
export default class IntroSlides extends Vue {
  // Boolean tells if dialog is open or not
  @Prop({ required: true, type: Boolean })
  displayDialog!: boolean;
  // List of slides
  @Prop({ required: true, type: Array })
  slidesProp!: ISlide[];
  // Current activity title
  @Prop({ required: true, type: String })
  title!: string;

  slides: ISlide[] = [];
  // Boolean tells if start button is displayed
  private displayStart = false;
  // Index of the current slide
  private currentSlideIndex = 0;
  // Index of the current slide element
  private lastDisplayedElIndex = 0;

  created(): void {
    this.slides = JSON.parse(JSON.stringify(this.slidesProp));
    const sortByOrderNumber = (
      elA: ISlide | ISlideElement,
      elB: ISlide | ISlideElement
    ) => elA.order_number - elB.order_number;
    this.slides.sort(sortByOrderNumber);
    this.slides.map((slide) => {
      if (slide.elements?.length) {
        slide.elements.sort(sortByOrderNumber);
      }
    });
    this.initSlide();
  }

  @Watch("displayDialog")
  dialogChanged(val: boolean): void {
    val || this.close();
  }

  initSlide(): void {
    if (this.slides[0]) {
      if (this.slides[0].elements) {
        Vue.set(this.slides[0].elements[0], "display", true);
      }
    }
  }

  close(): void {
    this.displayDialog &&= false;
    this.$nextTick(() => {
      this.displayStart &&= false;
      this.lastDisplayedElIndex = 0;
      this.currentSlideIndex = 0;
      this.slides.map((slide) => {
        if (slide.elements?.length) {
          slide.elements.map((slideEl) => {
            slideEl.display &&= false;
          });
        }
      });
      this.initSlide();
    });
  }

  getUF(key: string): string {
    return UFliterals[key] || "";
  }

  displayNext(): void {
    let currentSlide = this.slides[this.currentSlideIndex];
    if (currentSlide && currentSlide.elements?.length) {
      if (this.lastDisplayedElIndex + 1 < currentSlide.elements.length) {
        // if next el then display next
        this.lastDisplayedElIndex += 1;
        if (currentSlide.elements[this.lastDisplayedElIndex]) {
          this.$set(
            currentSlide.elements[this.lastDisplayedElIndex],
            "display",
            true
          );
          this.displayStart =
            currentSlide.elements.length === this.lastDisplayedElIndex + 1 &&
            this.slides.length === this.currentSlideIndex + 1;
        }
        return;
      }
      // change to next slide
      if (this.currentSlideIndex + 1 < this.slides.length) {
        this.currentSlideIndex += 1;
        let newSlide = this.slides[this.currentSlideIndex];
        currentSlide = Object.assign({}, { ...newSlide });
        if (currentSlide.elements) {
          this.lastDisplayedElIndex = 0;
          Vue.set(currentSlide.elements[0], "display", true);
          this.displayStart =
            currentSlide.elements.length <= this.lastDisplayedElIndex + 1;
        }
        return;
      }
      this.displayStart = true;
    }
  }

  start(): void {
    this.$emit("start");
  }
}
