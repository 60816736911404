
import { Component, Prop, Vue } from "vue-property-decorator";
import { ISlideElement } from "@/schemas/ISlideElement";
import { apiUrl } from "@/env";

@Component({
  name: "SlideElement",
})
export default class SlideElement extends Vue {
  // Slide element
  @Prop({ type: Object, required: true })
  element!: ISlideElement;

  // Get File/Img source
  getSrc(slideId: string, fileName: string): string {
    return `${apiUrl}/slides/${slideId}/files/${fileName}`;
  }
}
